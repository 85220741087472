import { Stack, useMediaQuery } from '@mui/material';
import React from 'react';



const PrivacyContainer = () => {
    const smUp = useMediaQuery('(max-width : 650px)')
    return (
        <div className='privacy-container'>
            <Stack sx={{ pt: 3 }}>
                <h3 style={{ color: 'rgb(143, 150, 140)', textAlign: 'center', fontWeight: 800 }}>Privacy Policy</h3>
            </Stack>
            <Stack sx={{ mt: 3, mb: 2, mx: 'auto', borderRadius: '5px', height: smUp ? '58vh' : '62vh', overflow: 'auto' }} className='privacy-text'>
                <h1>Table of Contents</h1>
                <ul>
                    <li><a href="#privacy-policy">Privacy Policy</a></li>
                    <li><a href="#terms-of-service">Terms of Service or Terms and Conditions</a></li>
                    <li><a href="#cookie-policy">Cookies Policy</a></li>
                    <li><a href="#website-disclaimer">Website Disclaimer</a></li>
                    <li><a href="#data-protection-ammendum">Data Protection Addendum</a></li>
                    <li><a href="#acceptable-use-policy">Acceptable Use Policy</a></li>
                </ul>

                <h1 id="privacy-policy">Privacy Policy for <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a></h1>
                <p><strong>Last Updated: 28th of August, 2023</strong></p>

                <h2 id="introduction">Introduction</h2>
                <p>Welcome to <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> ("the Website," "we," "us," "our"). Your privacy is of the utmost importance to us. This Privacy Policy outlines how we collect, use, and safeguard your personal data in compliance with the General Data Protection Regulation (GDPR) and other applicable international laws.</p>

                <h2 id="table-of-contents">Table of Contents</h2>
                <ol>
                    <li><a href='#data-collection'>Data Collection</a></li>
                    <li><a href='#data-usage'>Data Usage</a></li>
                    <li><a href='#data-sharing-and-disclosure'>Data Sharing and Disclosure</a></li>
                    <li><a href='#cookies'>Cookies</a></li>
                    <li><a href='#data-security'>Data Security</a></li>
                    <li><a href='#your-rights-and-choices'>Your Rights and Choices</a></li>
                    <li><a href='#international-data-transfers'>International Data Transfers</a></li>
                    <li><a href='#changes-to-this-privacy-policy'>Changes to this Privacy Policy</a></li>
                    <li><a href='#1-policy-contact-information'>Contact Information</a></li>
                </ol>

                <h2 id="data-collection">1. Data Collection</h2>
                <h3 id="information-you-provide">Information You Provide to Us:</h3>
                <ul>
                    <li><strong>Account Information:</strong> When you create an account on <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a>, either directly or through social media sign-ins like Google, we collect personal data such as your name, email address, and password.</li>
                </ul>

                <h3 id="information-we-collect">Information We Automatically Collect:</h3>
                <ul>
                    <li><strong>Analytics:</strong> We collect anonymized data to understand user behavior and improve our services.</li>
                </ul>

                <h2 id="data-usage">2. Data Usage</h2>
                <p>The information we collect is used for:</p>
                <ul>
                    <li>Enabling your access to our services.</li>
                    <li>Providing customer support.</li>
                    <li>Improving and customizing our services.</li>
                    <li>Complying with legal requirements and preventing fraud.</li>
                </ul>

                <h2 id="data-sharing-and-disclosure">3. Data Sharing and Disclosure</h2>
                <p>We will not sell or share your data with third-party organizations, except:</p>
                <ul>
                    <li>To comply with legal obligations.</li>
                    <li>With your explicit consent.</li>
                    <li>To service providers who assist in our business operations.</li>
                </ul>

                <h2 id="cookies">4. Cookies</h2>
                <p>Our website uses cookies to enhance user experience. For more details, refer to our Cookie Policy.</p>

                <h2 id="data-security">5. Data Security</h2>
                <p>We employ state-of-the-art security measures to protect your personal data. However, no system can be guaranteed to be 100% secure.</p>

                <h2 id="your-rights-and-choices">6. Your Rights and Choices</h2>
                <p>Under GDPR and applicable international laws, you have the right to:</p>
                <ul>
                    <li>Access your data.</li>
                    <li>Correct inaccurate data.</li>
                    <li>Delete your data.</li>
                    <li>Limit or object to data processing.</li>
                </ul>
                <p>To exercise these rights, contact us at the email provided below.</p>

                <h2 id="international-data-transfers">7. International Data Transfers</h2>
                <p>We comply with international data protection laws when transferring your personal data across borders.</p>

                <h2 id="changes-to-this-privacy-policy">8. Changes to this Privacy Policy</h2>
                <p>We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page, and your continued use of the Website indicates your agreement to the revised terms.</p>

                <h2 id="1-policy-contact-information">9. Contact Information</h2>
                <p>For any questions, concerns, or to exercise your rights, please contact us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>

                <h1 id="terms-of-service">Terms of Service for <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a></h1>
                <p><strong>Last Updated: 28th of August, 2023</strong></p>

                <h2 id="introduction">Introduction</h2>
                <p>Welcome to <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> ("the Website," "we," "us," "our"). By accessing or using our Website and its services ("Services"), you ("the User") agree to comply with and be bound by the following Terms of Service ("Terms").</p>

                <h2 id="table-of-contents">Table of Contents</h2>
                <ol>
                    <li><a href='#acceptance-of-terms'>Acceptance of Terms</a></li>
                    <li><a href='#description-of-services'>Description of Services</a></li>
                    <li><a href='#user-registration-and-responsibilities'>User Registration and Responsibilities</a></li>
                    <li><a href='#privacy-and-data-protection'>Privacy and Data Protection</a></li>
                    <li><a href='#content-ownership'>Content Ownership</a></li>
                    <li><a href='#restrictions-on-use'>Restrictions on Use</a></li>
                    <li><a href='#termination'>Termination</a></li>
                    <li><a href='#disclaimers'>Disclaimers</a></li>
                    <li><a href='#limitation-of-liability'>Limitation of Liability</a></li>
                    <li><a href='#governing-law'>Governing Law</a></li>
                    <li><a href='#changes-to-terms'>Changes to Terms</a></li>
                    <li><a href='#contact-information'>Contact Information</a></li>
                </ol>

                <h2 id="acceptance-of-terms">1. Acceptance of Terms</h2>
                <p>Your access to and use of our Services signifies your acceptance of these Terms. If you do not agree, please discontinue your use of the Website.</p>

                <h2 id="description-of-services">2. Description of Services</h2>
                <p>We offer a variety of AI tools and services through our Website. We reserve the right to enhance, modify, or discontinue any Services at our sole discretion.</p>

                <h2 id="user-registration-and-responsibilities">3. User Registration and Responsibilities</h2>
                <p>Users may create accounts either directly or through social media sign-ins. Users are responsible for:</p>
                <ul>
                    <li>Maintaining the confidentiality of their account credentials.</li>
                    <li>All activities that occur under their account.</li>
                </ul>

                <h2 id="privacy-and-data-protection">4. Privacy and Data Protection</h2>
                <p>Your use of the Services is also governed by our Privacy Policy, which can be found above.</p>

                <h2 id="content-ownership">5. Content Ownership</h2>
                <p>All content, including but not limited to AI tools and textual information, is the exclusive property of <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a>, protected by copyright laws.</p>

                <h2 id="restrictions-on-use">6. Restrictions on Use</h2>
                <p>Users shall not:</p>
                <ul>
                    <li>Engage in illegal activities or violate local and international laws.</li>
                    <li>Use the Services to harm, harass, or defraud others.</li>
                </ul>

                <h2 id="termination">7. Termination</h2>
                <p>We reserve the right to suspend or terminate User accounts and access to Services for violations of these Terms or applicable laws.</p>

                <h2 id="disclaimers">8. Disclaimers</h2>
                <p>The Services are provided "as is" and without warranties of any kind, either express or implied.</p>

                <h2 id="limitation-of-liability">9. Limitation of Liability</h2>
                <p>In no event shall <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the Services.</p>

                <h2 id="governing-law">10. Governing Law</h2>
                <p>These Terms are governed by the laws of the United Kingdom, without regard to its conflict of law principles.</p>

                <h2 id="changes-to-terms">11. Changes to Terms</h2>
                <p>We reserve the right to change these Terms at any time. Continued use of the Services after changes signifies your acceptance of the revised Terms.</p>

                <h2 id="contact-information">12. Contact Information</h2>
                <p>For any questions or concerns regarding these Terms, please contact us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>

                <h1 id="cookie-policy">Cookie Policy for <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a></h1>
                <p><strong>Last Updated: 28th of August, 2023</strong></p>

                <h2 id="introduction">Introduction</h2>
                <p>Welcome to <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> ("the Website," "we," "us," "our"). This Cookie Policy explains how we use cookies and similar technologies to recognize, collect, and store information during your visit to our Website.</p>

                <h2 id="table-of-contents">Table of Contents</h2>
                <ol>
                    <li><a href='#what-are-cookies'>What Are Cookies?</a></li>
                    <li><a href='#types-of-cookies-we-use'>Types of Cookies We Use</a></li>
                    <li><a href='#how-we-use-cookies'>How We Use Cookies</a></li>
                    <li><a href='#third-party-cookies'>Third-Party Cookies</a></li>
                    <li><a href='#managing-your-cookies'>Managing Your Cookies</a></li>
                    <li><a href='#changes-to-this-cookie-policy'>Changes to this Cookie Policy</a></li>
                    <li><a href='#cookie-contact-information'>Contact Information</a></li>
                </ol>

                <h2 id="what-are-cookies">1. What Are Cookies?</h2>
                <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They enable the website to remember your actions and preferences over time.</p>

                <h2 id="types-of-cookies-we-use">2. Types of Cookies We Use</h2>
                <ul>
                    <li><strong>Strictly Necessary Cookies</strong>: These are essential for the website's basic functionalities.</li>
                    <li><strong>Performance Cookies</strong>: These cookies collect information about how visitors use the Website.</li>
                    <li><strong>Functional Cookies</strong>: These allow the Website to remember choices you make to give you better functionality and personal features.</li>
                </ul>

                <h2 id="how-we-use-cookies">3. How We Use Cookies</h2>
                <p>We use cookies for various purposes:</p>
                <ul>
                    <li><strong>Authentication</strong>: To identify users and allow them to sign into their accounts.</li>
                    <li><strong>Performance Monitoring</strong>: To analyze how users interact with the Website to improve its functionality.</li>
                    <li><strong>Personalization</strong>: To tailor content based on user preferences and behavior.</li>
                </ul>

                <h2 id="third-party-cookies">4. Third-Party Cookies</h2>
                <p>We use third-party cookies, such as Google Analytics, to collect aggregate data for statistical purposes. These third-party cookies are governed by their respective Cookie Policies, which can be accessed on their websites.</p>

                <h2 id="managing-your-cookies">5. Managing Your Cookies</h2>
                <p>You can manage or delete cookies using your browser settings. However, this may affect the functionality of the Website.</p>

                <h2 id="changes-to-this-cookie-policy">6. Changes to this Cookie Policy</h2>
                <p>We reserve the right to update this Cookie Policy at any time. Any changes will be posted on this page.</p>

                <h2 id="cookie-contact-information">7. Contact Information</h2>
                <p>For any questions or concerns regarding this Cookie Policy, please contact us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>

                <h1 id='website-disclaimer'>Website Disclaimer for <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a></h1>
                <p><strong>Last Updated: 28th of August, 2023</strong></p>

                <h2>Introduction</h2>
                <p>Welcome to <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> ("the Website," "we," "us," "our"). By accessing or using our Website and its services ("Services"), you ("the User") acknowledge that you have read, understood, and agree to be bound by the following terms and conditions ("Disclaimer").</p>

                <h2>Table of Contents</h2>
                <ol>
                    <li><a href='#no-professional-advice'>No Professional Advice</a></li>
                    <li><a href='#limitation-of-warranties'>Limitation of Warranties</a></li>
                    <li><a href='#website-limitation-of-liability'>Limitation of Liability</a></li>
                    <li><a href='#third-party-links-and-tools'>Third-Party Links and Tools</a></li>
                    <li><a href='#website-governing-law'>Governing Law</a></li>
                    <li><a href='#changes-to-disclaimer'>Changes to this Disclaimer</a></li>
                    <li><a href='#website-contact-info'>Contact Information</a></li>
                </ol>

                <h2 id='no-professional-advice'>1. No Professional Advice</h2>
                <p>The information and tools available on the Website are for informational and educational purposes only and are not a substitute for professional advice.</p>

                <h2 id='limitation-of-warranties'>2. Limitation of Warranties</h2>
                <p>The Services and all information, tools, and resources are provided "as is" and without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

                <h2 id='website-limitation-of-liability'>3. Limitation of Liability</h2>
                <p>In no event shall <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> or its owners, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of, or the inability to use, the Website or its Services.</p>
                <h2 id='third-party-links-and-tools'>4. Third-Party Links and Tools</h2>
                <p>Our Website may contain links to third-party websites and tools. These links are provided for convenience only. We do not endorse, approve, or control these external sites and are not responsible for the content, services, or other material found on them.</p>

                <h2 id='website-governing-law'>5. Governing Law</h2>
                <p>This Disclaimer is governed by the laws of the United Kingdom. Any disputes shall be resolved in the appropriate courts in the United Kingdom.</p>

                <h2 id='changes-to-disclaimer'>6. Changes to this Disclaimer</h2>
                <p>We reserve the right to modify or update this Disclaimer at any time. Your continued use of the Website will constitute your acceptance of the revised Disclaimer.</p>

                <h2 id='website-contact-info'>7. Contact Information</h2>
                <p>For any questions or concerns regarding this Disclaimer, please contact us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>

                <h1 id='data-protection-ammendum'>Data Protection Addendum for <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a></h1>
                <p><strong>Last Updated: 28th of August, 2023</strong></p>

                <p>This Data Protection Addendum ("Addendum") forms part of the Terms of Service ("Agreement") between:</p>
                <p><strong>Data Controller</strong>: The User ("You," "Your")</p>
                <p><strong>Data Processor</strong>: <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> ("We," "Our," "Us")</p>
                <p>Collectively referred to as the "Parties."</p>

                <h2>Table of Contents</h2>
                <ol>
                    <li><a href='#definitions'>Definitions</a></li>
                    <li><a href='#data-processing'>Data Processing</a></li>
                    <li><a href='#security-measures'>Security Measures</a></li>
                    <li><a href='#sub-processors'>Sub-Processors</a></li>
                    <li><a href='#data-subject-rights'>Data Subject Rights</a></li>
                    <li><a href='#data-transfers'>Data Transfers</a></li>
                    <li><a href='#audit-rights'>Audit Rights</a></li>
                    <li><a href='#liablilty'>Liability</a></li>
                    <li><a href='#data-governing-law'>Governing Law</a></li>
                    <li><a href='#changes-to-this-ammendium'>Changes to this Addendum</a></li>
                    <li><a href='#data-contact-info'>Contact Information</a></li>
                </ol>

                <h2 id='definitions'>1. Definitions</h2>definitions
                <p>"Personal Data," "Data Controller," "Data Processor," and "Data Subject" shall have the meanings given to them in the GDPR.</p>

                <h2 id='data-processing'>2. Data Processing</h2>
                <p>We agree to only process Personal Data in accordance with the Agreement, this Addendum, and lawful instructions provided by You, the Data Controller.</p>

                <h2 id='security-measures'>3. Security Measures</h2>
                <p>We implement appropriate technical and organizational measures to protect Personal Data against unauthorized or unlawful processing and accidental loss, destruction, or damage.</p>

                <h2 id='sub-processors'>4. Sub-Processors</h2>
                <p>We may engage sub-processors to process Personal Data. We shall inform You of any changes to such sub-processors and ensure they are bound by data protection obligations comparable to those set out in this Addendum.</p>

                <h2 id='data-subject-rights'>5. Data Subject Rights</h2>
                <p>We will assist You in fulfilling Data Subject requests to exercise their rights under GDPR, including access, rectification, and erasure of Personal Data.</p>

                <h2 id='data-transfers'>6. Data Transfers</h2>
                <p>If Personal Data is transferred outside of the European Economic Area, We will ensure appropriate safeguards are in place in compliance with GDPR.</p>

                <h2 id='audit-rights'>7. Audit Rights</h2>
                <p>We shall make available all necessary information to demonstrate compliance with this Addendum and allow for audits conducted by You or an independent third-party auditor.</p>

                <h2 id='liablilty'>8. Liability</h2>
                <p>Liability for any breaches of this Addendum shall be governed by the terms and conditions of the Agreement.</p>

                <h2 id='data-governing-law'>9. Governing Law</h2>
                <p>This Addendum shall be governed by and construed in accordance with the laws of the United Kingdom.</p>

                <h2 id='changes-to-this-ammendium'>10. Changes to this Addendum</h2>
                <p>We reserve the right to update this Addendum at any time. Changes will be posted on this page.</p>

                <h2 id='data-contact-info'>11. Contact Information</h2>
                <p>For questions or concerns regarding this Addendum, please contact us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>

                <h1 id='acceptable-use-policy'>Acceptable Use Policy for <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a></h1>
                <p><strong>Last Updated: 28th of August, 2023</strong></p>

                <h2>Introduction</h2>
                <p>This Acceptable Use Policy ("Policy") governs your use of the services and websites provided by <a href="http://chisquareresearch.com/">ChiSquareResearch.com</a> ("We," "Our," "Us"). By using our services, you agree to comply with this Policy.</p>

                <h2>Table of Contents</h2>
                <ol>
                    <li><a href='#general-use'>General Use</a></li>
                    <li><a href='#prohibited-activities'>Prohibited Activities</a></li>
                    <li><a href='#data-protection-and-privacy'>Data Protection and Privacy</a></li>
                    <li><a href='#monitoring-and-enforcement'>Monitoring and Enforcement</a></li>
                    <li><a href='#consequences-of-violation'>Consequences of Violation</a></li>
                    <li><a href='#reporting-violations'>Reporting Violations</a></li>
                    <li><a href='#changes-to-this-policy'>Changes to this Policy</a></li>
                    <li><a href='#acceptable-contact-info'>Contact Information</a></li>
                </ol>

                <h2 id='general-use'>1. General Use</h2>
                <p>You agree to use our services only for lawful purposes, and in a manner that does not infringe upon the rights of others or restrict or inhibit their use of the services.</p>

                <h2 id='prohibited-activities'>2. Prohibited Activities</h2>
                <p>You may not:</p>
                <ul>
                    <li>Engage in unlawful activities, including but not limited to fraud, phishing, or distribution of malware.</li>
                    <li>Distribute copyrighted or proprietary materials without authorization.</li>
                    <li>Harass, intimidate, or engage in discriminatory activities.</li>
                    <li>Manipulate, abuse, or exploit any aspect of our services to gain unauthorized access or disrupt the normal flow of services.</li>
                </ul>

                <h2 id='data-protection-and-privacy'>3. Data Protection and Privacy</h2>
                <p>Your use of our services is subject to our Privacy Policy, which is compliant with GDPR and other relevant data protection laws. You agree to respect the privacy and confidentiality of others' information while using our services.</p>

                <h2 id='monitoring-and-enforcement'>4. Monitoring and Enforcement</h2>
                <p>We reserve the right to monitor your use of our services to ensure compliance with this Policy. We may take appropriate actions against any user for violations.</p>

                <h2 id='consequences-of-violation'>5. Consequences of Violation</h2>
                <p>Violations of this Policy may result in suspension or termination of your account and legal action where deemed necessary.</p>

                <h2 id='reporting-violations'>6. Reporting Violations</h2>
                <p>To report a violation of this Policy, please email us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>

                <h2 id='changes-to-this-policy'>7. Changes to this Policy</h2>
                <p>We reserve the right to amend this Policy at any time. Changes will be updated on this page, and your continued use of our services constitutes acceptance of the updated Policy.</p>

                <h2 id='acceptable-contact-info'>8. Contact Information</h2>
                <p>For any questions regarding this Policy, please contact us at <a href="mailto:contact@chisquareresearch.com">contact@chisquareresearch.com</a>.</p>




            </Stack>
        </div>
    )
}

export default PrivacyContainer;