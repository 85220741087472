import { Chip, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { useLocation, useNavigate } from 'react-router';


const BACKGROUND_COLOR = 'rgb(22, 22, 22)'

const Projects_List = [{
    name: 'Established',
    link: 'https://established.chisquareresearch.com/'
}]

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const smUp = useMediaQuery('(max-width : 650px)')
    const redirectToUrl = (url) => window.open(url, "_blank", "noreferrer");

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        smUp ?
            <>
                <Stack sx={{ bgcolor: BACKGROUND_COLOR, minHeight: location.pathname === '/' && smUp ? '18vh' : '8vh', cursor: 'pointer', pt: 2, mb: 'auto' }} onClick={() => navigate('/')} className={smUp && location.pathname === '/' && 'background'} direction='row' justifyContent={'space-between'}>
                    <Stack>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ marginLeft: '10px', ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon sx={{ color: '#ffffff' }} />
                        </IconButton>
                    </Stack>
                    <Stack>
                        <Typography variant='h5' sx={{ marginLeft: '-35px', color: '#ffffff', fontFamily: 'League Spartan, sans-serif', fontWeight: 800, mt: 0.75 }}>Chisquare Research</Typography>
                    </Stack>
                    {/* Add Menu options easily over here and pass the url to redirecct function writtem above using onClick event*/}
                    <Stack></Stack>
                </Stack >
                <Drawer
                    anchor={'left'}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Stack sx={{ p: 1, pt: 2, textAlign: 'center', width: '40vh', fontFamily: 'League Spartan, sans-serif', fontWeight: 800 }}>
                        <Typography> Projects</Typography>
                    </Stack>
                    <Divider />
                    <List>
                        {Projects_List.map((item, index) => (
                            <ListItem key={index} onClick={() => redirectToUrl(item?.link)}>
                                <ListItemIcon>
                                    <AcUnitIcon sx={{ color: 'red' }} />
                                </ListItemIcon>
                                <ListItemButton sx={{ marginLeft: '-30px' }}>
                                    <ListItemText primary={item.name} />
                                    <Chip label="Live" color="warning" />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </>
            :
            <Stack sx={{ bgcolor: BACKGROUND_COLOR, minHeight: location.pathname === '/' && smUp ? '18vh' : '11vh', cursor: 'pointer' }} onClick={() => navigate('/')} className={smUp && location.pathname === '/' && 'background'}>
                <Typography variant='h4' sx={{ mx: 'auto', mt: smUp ? 1.5 : 2, color: '#ffffff', fontFamily: 'League Spartan, sans-serif', fontWeight: 800 }}>Chisquare Research</Typography>
                <Typography variant='h6' sx={{ mx: 'auto', mt: 0.25, color: '#ffffff', fontFamily: 'League Spartan, sans-serif', fontWeight: 800 }} onClick={() => redirectToUrl('https://established.chisquareresearch.com/')}>Established</Typography>
                {/* Add Menu options easily over here and pass the url to redirecct function writtem above using onClick event*/}
            </Stack >
    )
}

export default Header;