import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const BACKGROUND_COLOR = 'rgb(22, 22, 22)'

const Footer = () => {
    const smUp = useMediaQuery('(max-width : 650px)')
    const navigate = useNavigate();
    return (
        <div style={{ backgroundColor: BACKGROUND_COLOR, height: smUp ? '20vh ' : '15vh', paddingTop: '3vh' }}>
            <p style={{ color: 'rgb(169, 169, 169)', textAlign: 'center', fontSize: smUp && '13px' }}>Copyright © 2023 Chisquare Research - All Rights Reserved.</p>
            <p style={{ color: 'white', textAlign: 'center', cursor: 'pointer', fontSize: smUp && '14px' }} onClick={() => navigate('/privacy-policy')}>PRIVACY POLICY</p>
        </div>
    )
}

export default Footer;