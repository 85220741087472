import { Alert, Button, CircularProgress, Stack, TextField, useMediaQuery } from '@mui/material';
import emailjs from '@emailjs/browser';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useRef, useState } from 'react';


const MessagerForm = (props) => {
    const ref = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [snackBar, setSnackBar] = useState(false);
    const [message, setMessage] = useState('');
    const smUp = useMediaQuery('(max-width : 650px)')
    const handleClick = () => {
        setSnackBar(true);
    };

    const handleClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar(false);
    };
    const sendEmail = (e) => {
        if (name === '' || email === '' || message === '') return;
        setIsLoading(true)
        emailjs.sendForm('service_l0edbkc', 'template_b0g1t62', ref.current, 'rnwagt2MLDiWJC3Zp')
            .then((result) => {
                handleClick();
                setIsLoading(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <>
            <Stack sx={{ minWidth: '300px', width: smUp ? '100%' : '45vh', bgcolor: 'rgb(255, 255, 255)', overflow: 'auto', height: smUp ? '93vh' : '80vh', borderRadius: '5px', marginBottom: smUp ? '-100px' : '10px', marginRight: smUp && '13px' }}>
                <Stack justifyContent={'center'} sx={{ bgcolor: '#DFE4DD', height: '80px', zIndex: 10 }}>
                    {smUp ?
                        <Stack sx={{ padding: '10px', width: '95%', margin: 'auto' }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <h4 style={{ textAlign: 'center' }}>Chisquare Research</h4>
                            <ClearIcon onClick={props.onClose} sx={{ height: '25px', width: '25px', color: 'white' , mb:1 }} />
                        </Stack> :
                        <Stack sx={{ padding: '10px' }}>
                            <h4 style={{ textAlign: 'center' }}>Chisquare Research</h4>
                        </Stack>
                    }
                </Stack>
                <Stack sx={{ width: '90%', mx: 'auto', borderRadius: '5px', mt: 3, bgcolor: 'rgba(226, 231, 224, 0.3)' }}>
                    <Stack>
                        <p style={{ textAlign: 'center', marginTop: 10, width: '90%' }} >Hi! Let us know how we can help and we’ll respond shortly.</p>
                    </Stack>
                </Stack>
                <form onSubmit={sendEmail} ref={ref}>
                    <Stack sx={{ mt: 3, }} alignItems={'center'}>
                        <TextField
                            required={true}
                            name='from_name'
                            error={name === ''}
                            helperText="Name required"
                            label='Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ width: '90%' }} />
                    </Stack>
                    <Stack sx={{ mt: 3, }} alignItems={'center'}>
                        <TextField
                            required={true}
                            name='email'
                            error={email === ''}
                            helperText="Email required"
                            label='Email'
                            placeholder='Email'
                            sx={{ width: '90%' }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </Stack>
                    <Stack sx={{ mt: 3, }} alignItems={'center'}>
                        <TextField
                            required={true}
                            name='message'
                            error={message === ''}
                            helperText="Message required"
                            placeholder='How can we help?'
                            multiline
                            rows={10}
                            sx={{ width: '90%' }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} />
                    </Stack>
                </form>
                {snackBar &&
                    <Stack sx={{ mt: 3 }}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Mail Sent ! We'll get back to you soon
                        </Alert>
                    </Stack>}
                <Stack sx={{ mt: 3, }} alignItems={'center'}>
                    <Button disabled={isLoading} type='submit' sx={{ bgcolor: '#DFE4DD', color: 'black', cursor: 'pointer', height: '45px', width: smUp ? '90%' : '100px', borderRadius: '5px' }} onClick={() => sendEmail()}>
                        {isLoading ? <CircularProgress color="success" size={30} /> : 'SEND'}
                    </Button>
                </Stack>
                <Stack sx={{ mt: 3, width: '90%', mx: 'auto', textAlign: 'center' }} alignItems={'center'}>
                    <Stack>
                        <p sx={{ textAlign: 'center' }}>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                    </Stack>
                </Stack>
            </Stack >
        </>
    )
}

export default MessagerForm;