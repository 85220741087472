import { useMediaQuery } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import React from 'react';



const BodyContainer = () => {
    const smUp = useMediaQuery('(max-width : 650px)')
    return (
        <div className={smUp ? 'white-background' : 'background'}>

            <h4 className={smUp ? 'white-text' : "heading-text"}>
                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed once, initially
                        'Investing in and building the next frontiers of human innovation.',
                        1000,
                        ''
                    ]}
                    speed={10}
                    style={{ fontSize: smUp ? '1em' : '1.5em' }}
                    repeat={Infinity}
                />
            </h4>

        </div>
    )
}

export default BodyContainer;