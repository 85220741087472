import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Messager from '../components/Messager';
import PrivacyContainer from '../components/PrivacyPolicy';


const PrivacyPage = () => {
    return (
        <>
            <Header />
            <PrivacyContainer />
            <Footer />
            <Messager />
        </>
    )
}

export default PrivacyPage;