import React from 'react';
import Header from '../components/Header';
import BodyContainer from '../components/Body';
import Footer from '../components/Footer';
import Messager from '../components/Messager';


const HomePage = () => {
    return (
        <>
            <Header />
            <BodyContainer />
            <Footer />
            <Messager />
        </>
    )
}

export default HomePage;