import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/index.css"
import HomePage from './pages/HomePage';
import PrivacyPage from './pages/PrivacyPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='privacy-policy' element={<PrivacyPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
