import { Stack, useMediaQuery } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import MessagerForm from './MessagerForm';


const Messager = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const smUp = useMediaQuery('(max-width : 650px)')

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ backgroundColor: open ? 'rgb(85, 85, 85)' : 'white', borderRadius: '50%', height: smUp ? '65px' : '80px', width: smUp ? '65px' : '80px', position: 'fixed', bottom: 20, right: 25, cursor: 'pointer' }} onClick={handleClick}>
                {open ? <ClearIcon sx={{ height: smUp ? '25px' : '40px', width: smUp ? '25px' : '40px', color: 'white' }} /> :
                    <ChatBubbleIcon color='black' sx={{ height: smUp ? '25px' : '40px', width: smUp ? '25px' : '40px' }} />}
            </Stack>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end">
                <MessagerForm onClose={() => setAnchorEl(null)} />
            </Popper>
        </>
    )
}

export default Messager;